// import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
// // get our fontawesome imports

// export default class Footer extends Component {
//   render() {
//     return (
//       <>
//         {/* contact footer  */}
//         <div
//           style={{ backgroundColor: "#f6f6f6" }}
//           className="container-fluid pt-3 pt-3 pb-3"
//         >
//           <div className="container">
//             <div className="row">
//               <div className="col-12 col-md-6 ps-5 text-dark">
//                 <h6 style={{ fontSize: "1.5rem" }}>
//                   <b>Looking for the Best IT Business Solutions?</b>
//                 </h6>
//                 <p className="fs-5">
//                   H&M Innovance LLP is here, We will help you.
//                 </p>
//               </div>
//               <div className="col-12 col-md-6 text-center d-flex justify-content-center align-items-center ">
//                 <NavLink to="/contactUs">
//                   <button className="contactbtn">Get a quote</button>
//                 </NavLink>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* footer */}
//         <div className="container-fluid bg-dark">
//           <div className="container p-3 text-white ">
//             <div className="row justify-content-around">
//               <div className="col-12 col-lg-4">
//                 <h6 style={{ fontSize: "1.5rem" }}>Contact Details</h6>
//                 <hr
//                   className="container"
//                   style={{
//                     height: "2px",
//                     backgroundColor: "#f5f5f5",
//                     color: "blueviolet",
//                     marginRight: "5px",
//                   }}
//                 />
//                 <ul className="list-unstyled">
//                   <li className="pb-2">
//                     UG-21, First Floor, Bestech City Center Mall, Dharuhera,
//                     123106
//                   </li>
//                   <li className="pb-2">
//                     +91-8059465353 &nbsp; &nbsp; +91-8979287631
//                   </li>
//                   {/* <li className="pb-2"></li> */}
//                   <li>
//                     <h6
//                       className="text-warning"
//                       style={{ fontSize: "1.25rem" }}
//                     >
//                       Open hours:
//                     </h6>
//                     <p>
//                       Mon-Sat: 9:30am - 6pm, <br /> Sunday: CLOSED
//                     </p>
//                   </li>
//                 </ul>
//               </div>
//               <div className="col-12 col-lg-3">
//                 <h4>Services</h4>
//                 <hr
//                   className="container"
//                   style={{
//                     height: "2px",
//                     backgroundColor: "#f5f5f5",
//                     marginLeft: "2px",
//                     // padding:"0 2px"
//                   }}
//                 />
//                 <ul className="list-unstyled">
//                   <li className="pb-1">
//                     <NavLink className="text-white new" to="/web-designing">
//                       Web Designing
//                     </NavLink>
//                   </li>
//                   <li className="pb-1">
//                     <NavLink className="text-white" to="/web-development">
//                       Web Development
//                     </NavLink>
//                   </li>
//                   <li className="pb-1">
//                     <NavLink className="text-white" to="/app-development">
//                       App Development
//                     </NavLink>
//                   </li>
//                   <li className="pb-1">
//                     <NavLink className="text-white" to="/digital-marketing">
//                       Digital Marketing
//                     </NavLink>
//                   </li>
//                   <li className="pb-1">
//                     <NavLink className="text-white" to="/automated-system">
//                       Automated System
//                     </NavLink>
//                   </li>
//                   <li className="pb-1">
//                     <NavLink className="text-white" to="/software-development">
//                       Software Development
//                     </NavLink>
//                   </li>
//                   <li className="pb-1">
//                     <NavLink className="text-white" to="/business-analysis">
//                       Business Analysis
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink className="text-white" to="/socialMedia-marketing">
//                       Social Media Marketing
//                     </NavLink>
//                   </li>
//                 </ul>
//               </div>
//               <div className="col-12 col-lg-3 ">
//                 <h4>Social Media</h4>
//                 <hr
//                   className="container"
//                   style={{
//                     height: "2px",
//                     backgroundColor: "#f5f5f5",
//                     color: "blueviolet",
//                     marginLeft: "5px",
//                   }}
//                 />
//                 <div className="row pb-7 mt-5 ">
//                   <ul
//                     className="list-unstyled "
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       marginBottom: "10px",
//                     }}
//                   >
//                     <li className="pb-2  " style={{ marginLeft: "23px" }}>
//                       <a
//                         className="text-white"
//                         target="_blank"
//                         href="https://wa.me/8059465353"
//                       >
//                         <img
//                           src="/assets/image/SociaI_Icons/Whatsapp.png"
//                           style={{ height: "30px", width: "30px" }}
//                           alt="Social Icon "
//                           title="Social Icon"
//                         />
//                       </a>
//                     </li>
//                     <li className="pb-2  " style={{ marginRight: "3px" }}>
//                       <a
//                         className="text-white"
//                         target="_blank"
//                         href="mailto:conatct@hminnovance.com"
//                       >
//                         <img
//                           src="/assets/image/SociaI_Icons/Gmail.png"
//                           style={{ height: "30px", width: "30px" }}
//                           alt="Social Icon"
//                         />
//                       </a>
//                     </li>
//                     <li className="pb-2  " style={{ marginRight: "95px" }}>
//                       <a
//                         className="text-white"
//                         target="_blank"
//                         href="https://www.facebook.com/hminnovancellp/"
//                       >
//                         <img
//                           src="/assets/image/SociaI_Icons/Facebook.png"
//                           style={{ height: "30px", width: "30px" }}
//                           alt="Social Icon"
//                           title="Social Icon"
//                         />
//                         {/* &nbsp;www.facebook.com */}
//                       </a>
//                     </li>
//                   </ul>
//                   <ul //col-lg-3 col-md-4 col-sm-6 d
//                     className="list-unstyled "
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-around",
//                     }}
//                   >
//                     <li
//                       className="pb-2"
//                       // style={{ marginLeft: "-35px" }}
//                     >
//                       <a
//                         className="text-white"
//                         target="_blank"
//                         href="https://www.linkedin.com/company/90863549/admin/"
//                       >
//                         <img
//                           src="/assets/image/SociaI_Icons/Linkedin.png"
//                           style={{ height: "30px", width: "30px" }}
//                           alt="Social Icon"
//                           title="Social Icon"
//                         />
//                         {/* &nbsp;www.linkedin.com */}
//                       </a>
//                     </li>
//                     <li className="pb-2" style={{ marginLeft: "-15px" }}>
//                       <a
//                         className="text-white"
//                         target="_blank"
//                         href="https://twitter.com/HMInnovanceLLP"
//                       >
//                         <img
//                           src="/assets/image\SociaI_Icons/Twitter.png"
//                           style={{ height: "32px", width: "32px" }}
//                           alt="Social Icon"
//                           title="Social Icon"
//                         />
//                         {/* &nbsp;www.twitter.com */}
//                       </a>
//                     </li>
//                     <li className="pb-2 " style={{ marginLeft: "-15px" }}>
//                       <a
//                         className="text-white"
//                         target="_blank"
//                         href="https://www.instagram.com/hminnovancellp/"
//                       >
//                         <img
//                           src="/assets/image/SociaI_Icons/Instagram.png"
//                           style={{ height: "35px", width: "35px" }}
//                           alt="Social Icon"
//                           title="Social Icon"
//                         />
//                         {/* &nbsp;www.instagram.com */}
//                       </a>
//                     </li>
//                     <li className="pb-2 " style={{ marginLeft: "-15px" }}>
//                       <a
//                         className="text-white"
//                         target="_blank"
//                         href="https://in.pinterest.com/HMINNOVACE/"
//                       >
//                         <img
//                           src="/assets/image/SociaI_Icons/Pintrest.png"
//                           style={{ height: "32px", width: "32px" }}
//                           alt="Social Icon"
//                           title="Social Icon"
//                         />
//                         {/* &nbsp;www.pinterest.com */}
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* copyright */}
//         <div style={{ backgroundColor: "gray" }} className="container-fluid">
//           <div className="row">
//             <div className="col-12 text-white d-flex justify-content-center">
//               <p className="text-center pt-2">
//                 &copy;&nbsp;Copyright All Right Reserved{" "}
//                 {new Date().getFullYear()}, H&M Innovance LLP |{" "}
//                 <a className="text-white" href="#">
//                   T&C
//                 </a>
//               </p>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// ......................................................................................................................................

import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
// get our fontawesome imports

export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      ConatctShow: true,
      serviceShow: false,
    };
  }
  dropdownFunc() {
    this.setState({
      ConatctShow: true,
      serviceShow: false,
    });
  }

  services() {
    this.setState({
      ConatctShow: false,
      serviceShow: true,
    });
  }

  render() {
    return (
      <>
        {/* contact footer  */}
        <div
          style={{ backgroundColor: "#f6f6f6" }}
          className="container-fluid pt-3 pt-3 pb-3"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 ps-1 text-dark">
                <h6 style={{ fontSize: "1.5rem" }}>
                  <b>Looking for the Best IT Business Solutions?</b>
                </h6>
                <p className="fs-5">
                  H&M Innovance LLP is here, We will help you.
                </p>
              </div>
              <div className="col-12 col-md-6 text-center d-flex justify-content-center align-items-center ">
                <NavLink to="/contactUs">
                  <button className="contactbtn">Get a quote</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="container-fluid bg-dark">
          <div className="container pt-5  pb-3 text-white ">
            <div className="row text-align-center" style={{justifyContent:"space-around"}}>
              <div
                className={`col-4   ${
                  this.state.ConatctShow ? "bgShow" : "bgHide"
                }`}
                onClick={() => this.dropdownFunc()}
              >
                <h6
                  style={{
                    fontSize: "1.5rem",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Contact{" "}
                </h6>
                <hr
                  className="container"
                  style={{
                    height: "3px",
                    marginRight: "10px ",
                    textAlign: "center",
                  }}
                />
              </div>
              {/* ...... */}

              <div
                className={`col-4  ${
                  this.state.serviceShow ? "bgShow" : "bgHide"
                }`}
                onClick={() => this.services()}
              >
                <h6
                  style={{
                    fontSize: "1.5rem",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Services
                </h6>
                <hr
                  className="container"
                  style={{
                    height: "3px",
                    marginLeft: "30px !important ",
                    textAlign: "center",
                  }}
                />
              </div>
            </div>

            {/* ......data show here ...... */}
            <div
              className="row justify-content-center "
              style={{ textAlign: "center" }}
            >
              {this.state.ConatctShow && (
                <div>
                  <div>
                    <ul className="list-unstyled ">
                      <li className="pb-2 text-align-center ">
                        UG-21, First Floor, Bestech City Center Mall, Dharuhera,
                        123106
                      </li>
                      <li className="pb-2">
                        +91-8059465353 &nbsp; &nbsp; +91-8979287631
                      </li>
                      <li className="pb-2"></li>
                      <li>
                        <h6
                          className="text-warning"
                          style={{ fontSize: "1.25rem" }}
                        >
                          Open hours:
                        </h6>
                        <p>
                          Mon-Sat: 9:30am - 6pm, <br /> Sunday: CLOSED
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              {this.state.serviceShow && (
                <div className="serviceList">
                  <div>
                    {" "}
                    <p>
                      <NavLink
                        className="text-white new text-align-left"
                        to="/web-designing"
                      >
                        • Web Designing
                      </NavLink>
                    </p>
                    <p>
                      {" "}
                      <NavLink className="text-white" to="/web-development">
                        • Web Development
                      </NavLink>
                    </p>
                    <p>
                      <NavLink className="text-white" to="/app-development">
                        • App Development
                      </NavLink>
                    </p>
                    <p>
                      <NavLink className="text-white" to="/digital-marketing">
                        • Digital Marketing
                      </NavLink>
                    </p>
                  </div>
                  <div>
                    <p>
                      <NavLink className="text-white" to="/automated-system">
                        <span className="dot">•</span> Automated System
                      </NavLink>
                    </p>
                    <p>
                      <NavLink
                        className="text-white"
                        to="/software-development"
                      >
                        • Software Development
                      </NavLink>
                    </p>
                    <p>
                      {/* <li className="pb-1"> */}
                      <NavLink className="text-white" to="/business-analysis">
                        • Business Analysis
                      </NavLink>
                    </p>
                    <p>
                      <NavLink
                        className="text-white"
                        to="/socialMedia-marketing"
                      >
                        • Social Media Marketing
                      </NavLink>
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* //////////////////////////  Links  ///////////////////////////////////// */}
            <div className=" linkIcon col-12 col-md-6 ps-5 m-auto justify-content-center">
              <a
                className="text-white"
                target="_blank"
                href="https://wa.me/8059465353"
              >
                <img
                  src="/assets/image/SociaI_Icons/Whatsapp.png"
                  alt="Social Icon "
                  title="Social Icon"
                />
              </a>
              <a
                className="text-white"
                target="_blank"
                href="mailto:conatct@hminnovance.com"
              >
                <img
                  src="/assets/image/SociaI_Icons/Gmail.png"
                  alt="Social Icon"
                />
              </a>
              <a
                className="text-white"
                target="_blank"
                href="https://www.facebook.com/hminnovancellp/"
              >
                <img
                  src="/assets/image/SociaI_Icons/Facebook.png"
                  alt="Social Icon"
                  title="Social Icon"
                />
              </a>
              <a
                className="text-white"
                target="_blank"
                href="https://www.linkedin.com/company/90863549/admin/"
              >
                <img
                  src="/assets/image/SociaI_Icons/Linkedin.png"
                  alt="Social Icon"
                  title="Social Icon"
                />
              </a>
              <a
                className="text-white"
                target="_blank"
                href="https://twitter.com/HMInnovanceLLP"
              >
                <img
                  src="/assets/image\SociaI_Icons/Twitter.png"
                  alt="Social Icon"
                  title="Social Icon"
                />
              </a>
              <a
                className="text-white"
                target="_blank"
                href="https://www.instagram.com/hminnovancellp/"
              >
                <img
                  src="/assets/image/SociaI_Icons/Instagram.png"
                  alt="Social Icon"
                  title="Social Icon"
                />
              </a>
              <a
                className="text-white"
                target="_blank"
                href="https://in.pinterest.com/HMINNOVACE/"
              >
                <img
                  src="/assets/image/SociaI_Icons/Pintrest.png"
                  alt="Social Icon"
                  title="Social Icon"
                />
              </a>
            </div>
          </div>
        </div>
        {/* copyright */}
        <div style={{ backgroundColor: "gray" }} className="container-fluid">
          <div className="row">
            <div className="col-12 text-white d-flex justify-content-center">
              <p className="text-center pt-2">
                &copy;&nbsp;Copyright All Right Reserved{" "}
                {new Date().getFullYear()}, H&M Innovance LLP |{" "}
                <a className="text-white" href="#">
                  T&C
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
