import React from "react";
import Header from "../../../component/commonheader/Header";
import PageHelmet from "../../../component/shared/PageHelmet";

import "../../../css/crm.css";
import Footer from "../../../component/shared/Footer";
export const Crm = () => {
  return (
    <>
      <PageHelmet
        // pageTitle="About Us | We Digitalize Your Dreams with Our Designing Skills"
        // description="H&M Innovance LLP is a digital marketing firm that builds and promotes brands to turn your sites' visualizations into reality. Trust us; we are aware of the pulse of your company."
        // keywords="About US Website Designing, Digital Agency, Digital Marketing, Internet Marketing"
        canonical="https://www.hminnovance.com/product/crm"
      />
      <Header />
      <section id="about" className="about section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12 ps-lg-5 mt-md-5">
              <div className="about-text">
                <h2>
                  We Provide the Best Quality <br /> Services Ever
                </h2>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Totam, labore reiciendis. Assumenda eos quod animi! Soluta
                  nesciunt inventore dolores excepturi provident, culpa beatae
                  tempora, explicabo corporis quibusdam corrupti. Autem,
                  quaerat. Assumenda quo aliquam vel, nostrum explicabo ipsum
                  dolor, ipsa perferendis porro doloribus obcaecati placeat
                  natus iste odio est non earum?
                </p>
                <a href="#" className="btn btn-warning">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="about-img">
                <img
                  src="https://freepngimg.com/thumb/samsung_mobile_phone/5-2-samsung-mobile-phone-png-hd.png"
                  alt=""
                  className="img-fluid"
                  //   style={{height:"2vw", }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* .... */}
      <div className="container mb-5">
        <h2 className="text-center mb-5">
          <strong>What We Build</strong>
        </h2>
        <div className="row m-auto d-flex justify-content-evenly">
          <div
            className="col-12 col-lg-6 mb-5 mb-lg-0  d-flex justify-content-center"
            // data-aos="flip-up"
          >
            <div
              className="card cardStyle cardShadow container"
              // style={{ width: "18rem" }}
            >
              <img
                src="assets/image/android-logo.png"
                className="card-img-top appImg"
                alt=" Best Android Development Company In Gurgaon"
              />
              <div className="card-body">
                <h3 className="card-title text-center">Android</h3>
                <p className="card-text">
                  Android is a mobile operating system based on a modified
                  version of the Linux kernel and other open-source software,
                  designed primarily for touchscreen mobile devices such as
                  smartphones and tablets.
                </p>
              </div>
            </div>
          </div>
          {/* <div
            className="col-12 col-lg-6 mb-5 mb-lg-0  d-flex justify-content-center"
            // data-aos="flip-down"
          >
            <div
              className="card cardStyle cardShadow container"
              style={{ width: "18rem" }}
            >
              <img
                src="assets/image/apple.png"
                className="card-img-top appImg"
                alt="iOS Development"
              />
              <div className="card-body">
                <h3 className="card-title text-center">iOS</h3>
                <p className="card-text">
                  iOS is a mobile operating system created and developed by
                  Apple Inc. exclusively for its hardware. It is the operating
                  system that powers many of the company's mobile devices,
                  including the iPhone.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-4 mb-5 mb-lg-0  d-flex justify-content-center"
            // data-aos="flip-down"
          >
            <div
              className="card cardStyle cardShadow container"
              style={{ width: "18rem" }}
            >
              <img
                src="assets/image/apple.png"
                className="card-img-top appImg"
                alt="iOS Development"
              />
              <div className="card-body">
                <h3 className="card-title text-center">iOS</h3>
                <p className="card-text">
                  iOS is a mobile operating system created and developed by
                  Apple Inc. exclusively for its hardware. It is the operating
                  system that powers many of the company's mobile devices,
                  including the iPhone.
                </p>
              </div>
            </div>
          </div> */}
          <div
            className="col-12 col-lg-4 d-flex justify-content-center"
            // data-aos="flip-up"
          >
            <div
              className="card  cardStyle cardShadow container"
              // style={{ width: "18rem" }}
            >
              <img
                src="assets/image/cross-platform.png"
                className="card-img-top appImg"
                alt="Cross Platform"
              />
              <div className="card-body">
                <h3 className="card-title text-center">Cross Platform</h3>
                <p className="card-text">
                  A cross-platform computer product or system is a product or
                  system that can work across multiple types of platforms or
                  operating environments. Different kinds of cross-platform
                  systems include both hardware and software systems
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ..update function . */}

      <div className="col-lg-5 col-md-12 col-12 ps-lg-5 mt-md-5">
        <div className="about-text">
          <h4>
            We Provide the Best Quality <br /> Services Ever
          </h4>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam,
            labore reiciendis. Assumenda eos quod animi! Soluta nesciunt
            inventore dolores excepturi provident, culpa beatae
          </p>
          <a href="#" className="btn btn-warning">
            Learn More
          </a>
        </div>
      </div>

      {/* .. */}

      {/* .............................Heading to work to Manage ............................................. */}
      <section id="about" className="about section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12 ps-lg-5 mt-md-5">
              <div className="about-text">
                <h4>
                  We Provide the Best Quality <br /> Services Ever
                </h4>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Totam, labore reiciendis. Assumenda eos quod animi! Soluta
                  nesciunt inventore dolores excepturi provident, culpa beatae
                </p>
                <a href="#" className="btn btn-warning">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="about-img">
                <img
                  src="https://freepngimg.com/thumb/samsung_mobile_phone/5-2-samsung-mobile-phone-png-hd.png"
                  alt=""
                  className="img-fluid"
                  //   style={{height:"2vw", }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* .......................................Manage Lead and deals..................................................... */}

      <section id="about" className="about section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-12">
              <div className="about-img">
                <img
                  src="https://cdn.kibrispdr.org/data/1794/smartphone-animated-gif-28.gif"
                  alt=""
                  className="img-fluid"
                  style={{ height: "30vw" }}
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-12 ps-lg-5 mt-md-5">
              <div className="about-text">
                <h5>
                  We Provide the Best Quality <br /> Services Ever
                </h5>
                <ul>
                  <li>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  </li>
                  <li>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  </li>
                </ul>
                <a href="#" className="btn btn-warning">
                  contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
