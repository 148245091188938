import React from "react";
import ReactDOM from "react-dom/client";
import "./css/style.css";
import "./index.css";
import App from "./App";
import { hydrate, render } from "react-dom";
// const root = ReactDOM.createRoot(document.getElementById('root'));
// const rootElement = document.getElementById("root");

// root.render(
//   <React.StrictMode>
//     <App/>
//   </React.StrictMode>
// );

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

rootElement.hasChildNodes() ? root.hydrate(<App />) : root.render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
