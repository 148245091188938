import { NavLink } from "react-router-dom";
const Content = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-lg-6 order-lg-2">
            <h2>
              <strong>Website Design</strong>
            </h2>
            <p>
              <span style={{ fontWeight: "bold" }}> H&amp;M INNOVANCE LLP</span>
              is a well-known web design company in Dharuhera. Our amazing
              development team creates custom web design and ecommerce websites.
              Many websites have been built by us in Chandigarh, Punjab,
              Haryana, Delhi, Noida, Gurgaon, and Dharuhera.
            </p>
            <p>
              Web design in an appealing manner is not only a career, but also a
              passion for our company.{" "}
              <a href="/" style={{ textDecoration: "none" }}>
                {" "}
                H&amp;M INNOVANCE LLP
              </a>{" "}
              is a leading web design Company in Dharuhera. We believe that the
              first impression of any website is its visual appeal. A good
              website design is the starting point for any website. We are a
              group of skilled designers, developers, marketers, and IT
              specialists who work together to produce remarkable designs and
              strategies that are suited to the demands and objectives of your
              organisation.
            </p>
            <p>
              H&amp;M INNOVANCE LLP is the leading web development company in
              Dharuhera. We have a dedicated Website Design team that has
              experience building professional websites of various types. If you
              are looking for{" "}
              <span style={{ fontWeight: "bold" }}>
                web design services in Dharuhera, Gurgaon, Noida, or Delhi{" "}
              </span>{" "}
              , please{" "}
              <a href="/contactUs" style={{ textDecoration: "none" }}>
                contact us{" "}
              </a>{" "}
              at +91- 8979287631. We provide user-friendly and responsive site
              designs to clients all over the world. In Dharuhera, H&amp;M
              INNOVANCE LLP also created the MYB LEARNING APP. Our dedicated
              team employs cutting-edge technology.
            </p>
          </div>
          <div className="col-12 col-lg-6 order-lg-1 d-flex justify-content-center align-items-center">
            <img
              className="img-fluid"
              src="assets/image/webdesign2.gif"
              alt="Best Web Development Company In Gurgaon"
              title="Best Web Development Company In Gurgaon"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Content;
